// Constants for this module
export const UNIFIED_USER_INFO_COOKIE_NAME = "unified_user_info";
export const SESSION_USERNAME_KEY = "username";

export const LOGOUT_REDIRECT_TO = "logout_redirect_to";

export enum UnifiedProvisionError {
  USERNAME_IN_USE = "USERNAME_IN_USE",
  GENERAL_ERROR = "GENERAL_ERROR",
  AUTH0_ERROR = "AUTH0_ERROR",
  AUTH0_EMAIL_MISMATCH = "AUTH0_EMAIL_MISMATCH",
  REGION_ERROR = "REGION_ERROR",
}

export enum UnifiedProvisionPlanError {
  GENERAL_ERROR = "GENERAL_ERROR",
  VALIDATE_2FA_REQUIRED = "VALIDATE_2FA_REQUIRED",
}

export enum IdentifierError {
  GENERAL_ERROR = "GENERAL_ERROR",
  BOT_DETECTION_ERROR = "BOT_DETECTION_ERROR",
  SSO_NOT_SET_UP_ERROR = "SSO_NOT_SET_UP_ERROR",
}
