export function validateOAuthRedirect(redirectUrl: string) {
  const allowedProtocols = ["https:"];
  const allowedHosts = [process.env.REACT_APP_API_BASE_URL, process.env.REACT_APP_EU_API_BASE_URL];
  const allowedPaths = ["/v3/oauth/authorize"];

  const decodedRedirectUrl = decodeURIComponent(redirectUrl);
  const parsedRedirectUrl = new URL(decodedRedirectUrl);

  if (!allowedProtocols.includes(parsedRedirectUrl.protocol)) {
    return false;
  }

  if (!allowedHosts.includes(parsedRedirectUrl.host)) {
    return false;
  }

  if (!allowedPaths.includes(parsedRedirectUrl.pathname)) {
    return false;
  }

  return true;
}

export function redirectToOAuthAuthorize(redirectUrl?: string) {
  try {
    const oAuthRedirect = redirectUrl || sessionStorage.getItem("oauth_redirect_to");
    if (!oAuthRedirect) {
      return "/oauth/error";
    }
    const decodedRedirect = decodeURIComponent(oAuthRedirect);
    const oAuthRedirectURL = new URL(decodedRedirect);
    if (validateOAuthRedirect(oAuthRedirectURL.href)) {
      return oAuthRedirectURL.href;
    }
  } catch (e) {
    console.warn(`failed to get oauth_redirect_to session storage`, e);
  }
  return "/oauth/error";
}
