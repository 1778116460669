import { AccountRegion } from "./types/regionalApi";

const euApiHost = process.env.REACT_APP_EU_API_BASE_URL;
const globalHost = process.env.REACT_APP_API_BASE_URL;

export const missingAccountRegionErrorMessage =
  "accountRegion is undefined, unable to determine regional api host";

type GetRegionalApiUrlOptions = {
  url: URL;
  region: AccountRegion;
};

export const getRegionalApiUrl = ({ url, region }: GetRegionalApiUrlOptions) => {
  if (isGlobalRegion(region)) {
    return applyGlobalApiHost(url);
  }

  if (isEURegion(region)) {
    return applyEUApiHost(url);
  }

  throw new Error(missingAccountRegionErrorMessage);
};

export const applyEUApiHost = (url: URL) => {
  const euBaseUrl = new URL(euApiHost);
  const euUrl = new URL(url.toString());

  euUrl.host = euBaseUrl.host;
  euUrl.hostname = euBaseUrl.hostname;

  return euUrl;
};

export const applyGlobalApiHost = (url: URL) => {
  const globalBaseUrl = new URL(globalHost);
  const globalUrl = new URL(url.toString());

  globalUrl.host = globalBaseUrl.host;
  globalUrl.hostname = globalBaseUrl.hostname;

  return globalUrl;
};

export const isGlobalRegion = (region: AccountRegion) => region === AccountRegion.Global;

export const isEURegion = (region: AccountRegion) => region === AccountRegion.EU;

export const getRegionalApiHost = (region: AccountRegion) => {
  if (isGlobalRegion(region)) {
    return globalHost;
  }

  if (isEURegion(region)) {
    return euApiHost;
  }

  // default case, should never happen but for type safety
  throw new Error(missingAccountRegionErrorMessage);
};
