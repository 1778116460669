import { AppState } from "@auth0/auth0-react";
import { UnifiedProvisionPlanError } from "./constants";
import { UnifiedProvisionEligibilityResult } from "./context";
import { fetchUnifiedLink, UnifiedProvisionPlanResponse } from "./fetch";

export const acceptTeammateInvite = async (
  access_token: string,
  id_token: string,
  appState?: AppState
) => {
  const acceptToken = appState?.token;
  if (!acceptToken) return;

  const unifiedLinkResponse = await fetchUnifiedLink(access_token, acceptToken, id_token);
  if (!unifiedLinkResponse) return; // Success case

  const { status, errors } = unifiedLinkResponse;
  const { error_id = "ERR_TEAMMATE_INVITE_INTERNAL", message = "An unknown error occurred" } =
    errors?.[0] || {};

  throw new Error(`Error ${status}: ${error_id} - ${message}`);
};

export const stripUndefined = (params: any) => {
  return Object.keys(params)
    .filter((k) => typeof params[k] !== "undefined")
    .reduce((acc, key) => ({ ...acc, [key]: params[key] }), {});
};

export const makeSureUserCanLink = (
  response: UnifiedProvisionPlanResponse
): UnifiedProvisionEligibilityResult => {
  if (!response.eligible) {
    return {
      eligible: response.eligible,
      error: response?.reason as UnifiedProvisionPlanError | undefined,
    };
  }

  return {
    eligible: response.eligible,
    email: response.email,
  };
};
