import {
  Anchor,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading,
  Paragraph,
} from "@twilio-paste/core";
import { useUID } from "react-uid";

import { UnifiedProvisionError } from "../../../unified-auth/constants";
import { AccountLinkErrorImage } from "../illustrations/AccountLinkError";

const contactSupportUrl = "https://support.sendgrid.com/hc/en-us";

export type AccountLinkErrorModalProps = {
  showModal: boolean;
  type: UnifiedProvisionError;
  onConfirm: () => void;
  onRetry: () => void;
};

export const errorModalHeader = {
  [UnifiedProvisionError.USERNAME_IN_USE]: "Linking unsuccessful. The email is already in use",
  [UnifiedProvisionError.AUTH0_ERROR]: "Something went wrong!",
  [UnifiedProvisionError.GENERAL_ERROR]: "Linking unsuccessful.",
  [UnifiedProvisionError.AUTH0_EMAIL_MISMATCH]: "Linking unsuccessful. Email mismatch",
  [UnifiedProvisionError.REGION_ERROR]: "Something went wrong!",
};

export const errorModalDescription = {
  [UnifiedProvisionError.USERNAME_IN_USE]: (
    <Paragraph>
      Your Twilio email is already in use by another user. We will be releasing support for linking
      multiple SendGrids account soon.
    </Paragraph>
  ),
  [UnifiedProvisionError.AUTH0_ERROR]: (
    <Paragraph>It looks like your session has expired.</Paragraph>
  ),
  [UnifiedProvisionError.GENERAL_ERROR]: (
    <Paragraph>An error has occurred and we&apos;re working to fix the problem!</Paragraph>
  ),
  [UnifiedProvisionError.AUTH0_EMAIL_MISMATCH]: (
    <Paragraph>
      The email associated with your Twilio user does not match the email associated with your
      SendGrid user. You can change your SendGrid or Twilio email prior to linking in your next
      login.
    </Paragraph>
  ),
  [UnifiedProvisionError.REGION_ERROR]: (
    <Paragraph>An error has occurred and we&apos;re working to fix the problem!</Paragraph>
  ),
};

const errorModalSubDescription = {
  [UnifiedProvisionError.USERNAME_IN_USE]: (
    <Paragraph marginBottom="space0">
      <Anchor href={contactSupportUrl}>Contact support</Anchor> for assistance.
    </Paragraph>
  ),
  [UnifiedProvisionError.AUTH0_ERROR]: (
    <Paragraph marginBottom="space0">
      Please log in as your Twilio user again to continue the account linking process.
    </Paragraph>
  ),
  [UnifiedProvisionError.GENERAL_ERROR]: (
    <Paragraph marginBottom="space0">
      We&apos;ll be up and running shortly. If you need immediate help from our support team please{" "}
      <Anchor href={contactSupportUrl}>contact us</Anchor>.
    </Paragraph>
  ),
  [UnifiedProvisionError.AUTH0_EMAIL_MISMATCH]: (
    <Paragraph marginBottom="space0">
      <Anchor href={contactSupportUrl}>Contact support</Anchor> for assistance.
    </Paragraph>
  ),
  [UnifiedProvisionError.REGION_ERROR]: (
    <Paragraph marginBottom="space0">
      We&apos;ll be up and running shortly. If you need immediate help from our support team please{" "}
      <Anchor href={contactSupportUrl}>contact us</Anchor>.
    </Paragraph>
  ),
};

const errorModalButton = {
  [UnifiedProvisionError.USERNAME_IN_USE]: "Go to SendGrid dashboard",
  [UnifiedProvisionError.AUTH0_ERROR]: "Go to Twilio login",
  [UnifiedProvisionError.GENERAL_ERROR]: "Go to SendGrid dashboard",
  [UnifiedProvisionError.AUTH0_EMAIL_MISMATCH]: "Go to SendGrid dashboard",
  [UnifiedProvisionError.REGION_ERROR]: "Go to SendGrid dashboard",
};

export const AccountLinkErrorModal = ({
  type,
  showModal,
  onConfirm,
  onRetry,
}: AccountLinkErrorModalProps) => {
  const ModalId = useUID();

  return (
    <Modal
      isOpen={showModal}
      onDismiss={onConfirm}
      size={"default"}
      ariaLabelledby={ModalId}
      data-role="error-modal">
      <ModalHeader>
        <ModalHeading as="h3" id={ModalId} data-testid="generic-error-modal">
          {errorModalHeader[type]}
        </ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box textAlign={"center"} paddingBottom={"space60"}>
          <AccountLinkErrorImage />
        </Box>
        {errorModalDescription[type]}
        {errorModalSubDescription[type]}
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          {type === UnifiedProvisionError.AUTH0_EMAIL_MISMATCH && (
            <Button variant="secondary" onClick={onRetry} data-testid="modal-retry-button">
              Attempt linking again
            </Button>
          )}
          <Button variant="primary" onClick={onConfirm} data-testid="modal-confirm-button">
            {errorModalButton[type]}
          </Button>
        </ModalFooterActions>
      </ModalFooter>
    </Modal>
  );
};
