import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  HelpText,
  Input,
  Label,
  Paragraph,
  SkeletonLoader,
  Text,
} from "@twilio-paste/core";
import { CopyIcon } from "@twilio-paste/icons/esm/CopyIcon";
import { LinkIcon } from "@twilio-paste/icons/esm/LinkIcon";

import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { NavbarContainer } from "../../NavbarContainer";
import { missingAccountRegionErrorMessage } from "../../regionalApi";
import { useAccountRegionCookie } from "../../sendgrid-auth/useAccountRegionCookie";
import { AccountRegion } from "../../types/regionalApi";
import { useSessionUsername, useUnifiedAuth } from "../../unified-auth";
import { UnifiedProvisionError } from "../../unified-auth/constants";
import { AccountProfileResult, UserTypeResult } from "../../unified-auth/context";
import { AccountLinkErrorModal } from "./components/AccountLinkErrorModal";
import { LogoAvatar } from "./components/LogoAvatar";

export const FIELD_REQUIRED = "This field is required.";
export const FRIENDLYNAME_LENGTH_MAX = 64;
export const FRIENDLYNAME_DEFAULT = `Maximum length is ${FRIENDLYNAME_LENGTH_MAX} characters.`;
export const FRIENDLYNAME_INVALID = "Invalid characters in friendly name.";

export const validateFriendlyName = (value: string) => {
  const trimmedValue = value.trim();

  if (trimmedValue === "") {
    return FIELD_REQUIRED;
  }

  // Max length check
  if (trimmedValue.length > FRIENDLYNAME_LENGTH_MAX) {
    return FRIENDLYNAME_DEFAULT;
  }

  // Regex check
  const regex = /^(?!.*\b(?:http:\/\/|https:\/\/|www\.|ftp:\/\/))[\w@._ -]{1,64}$/;
  if (!value.match(regex)) {
    return FRIENDLYNAME_INVALID;
  }

  return true;
};

export const ConfirmUnifiedAccountLink = () => {
  const [sessionUsername] = useSessionUsername();
  const [oldUsername] = useState(sessionUsername);
  const {
    confirmUnifiedAccountLinking,
    navigateToGuide,
    skipUnifiedAccountLinking,
    redirectToUnifiedAuthWithLinking,
    checkIfTeammateIsAdmin,
    isAuthenticatedWithSendGrid,
    getAccountProfile,
    updateFriendlyName,
    getUserType,
  } = useUnifiedAuth();
  const {
    user: unifiedUserInfo,
    isLoading: isAuthInfoLoading,
    logout: logoutWithTwilioAuth0,
  } = useAuth0();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorType, setErrorType] = useState<UnifiedProvisionError>(
    UnifiedProvisionError.GENERAL_ERROR
  );
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const friendlyNameEnabled = process.env.REACT_APP_ACCOUNT_FRIENDLY_NAME_ENABLED === "true";
  const [friendlyNameError, setFriendlyNameError] = useState("");
  const { accountRegion, setAccountRegionCookie } = useAccountRegionCookie();

  const enableQueries = friendlyNameEnabled && isAuthenticatedWithSendGrid;
  const {
    data: userType,
    isLoading: isUserTypeLoading,
    isError: isUserTypeError,
  } = useQuery<UserTypeResult>({
    enabled: enableQueries,
    queryKey: ["getUserType"],
    queryFn: async () => {
      const result = await getUserType(accountRegion ?? AccountRegion.Global);
      if (!accountRegion) {
        setAccountRegionCookie(result.region);
      }
      return result;
    },
    staleTime: 0,
  });

  const { data: accountProfile, isLoading: isAccountProfileLoading } =
    useQuery<AccountProfileResult>({
      enabled: enableQueries && Boolean(accountRegion),
      queryKey: ["getAccountProfile"],
      queryFn: async () => {
        if (accountRegion) {
          return getAccountProfile(accountRegion);
        }

        throw new Error(missingAccountRegionErrorMessage);
      },
      staleTime: 0,
    });

  const { data: isAdminAccount, isLoading: isAdminAccountLoading } = useQuery<boolean>({
    enabled: enableQueries && Boolean(accountRegion),
    queryKey: ["getTeammate"],
    queryFn: async () => {
      if (accountRegion) {
        return checkIfTeammateIsAdmin(accountRegion);
      }

      throw new Error(missingAccountRegionErrorMessage);
    },
    staleTime: 0,
  });

  const isSubuser = userType?.type === "subuser" || userType?.type === "reseller_customer_subuser";

  const [friendlyName, setFriendlyName] = useState(accountProfile?.friendly_name || "");

  const accountDetailsLoading =
    isAccountProfileLoading || isAdminAccountLoading || isUserTypeLoading;

  useEffect(() => {
    if (!isAuthInfoLoading && !unifiedUserInfo) {
      setErrorType(UnifiedProvisionError.AUTH0_ERROR);
      setShowErrorModal(true);
    }
  }, [isAuthInfoLoading, unifiedUserInfo]);

  useEffect(() => {
    if (isUserTypeError) {
      setErrorType(UnifiedProvisionError.REGION_ERROR);
      setShowErrorModal(true);
    }
  }, [isUserTypeError]);

  useEffect(() => {
    if (friendlyNameEnabled && !friendlyName && accountProfile?.friendly_name) {
      setFriendlyName(accountProfile.friendly_name);
    }
  }, [accountProfile, friendlyName, friendlyNameEnabled]);

  useEffect(() => {
    if (friendlyNameEnabled && friendlyName) {
      // Trigger validation whenever friendlyName changes
      const validationResult = validateFriendlyName(friendlyName);
      if (validationResult !== true) {
        setFriendlyNameError(validationResult);
      } else {
        setFriendlyNameError("");
      }
    }
  }, [friendlyName, friendlyNameEnabled]);

  const updateFriendlyNameMutation = useMutation({
    mutationFn: (args: { friendlyName: string; accountRegion: AccountRegion }) =>
      updateFriendlyName(args.friendlyName, args.accountRegion),
  });

  const onConfirmUnifiedAccountLinking = useCallback(async () => {
    if (!accountRegion) {
      setErrorType(UnifiedProvisionError.REGION_ERROR);
      setShowErrorModal(true);
      return;
    }

    setIsSubmitButtonDisabled(true);
    if (friendlyNameEnabled && isAdminAccount) {
      await updateFriendlyNameMutation.mutateAsync({
        friendlyName,
        accountRegion: accountRegion,
      });
    }
    const result = await confirmUnifiedAccountLinking(accountRegion);
    if (!result.success) {
      if (result.error === UnifiedProvisionError.USERNAME_IN_USE) {
        setErrorType(UnifiedProvisionError.USERNAME_IN_USE);
      } else if (result.error === UnifiedProvisionError.AUTH0_EMAIL_MISMATCH) {
        setErrorType(UnifiedProvisionError.AUTH0_EMAIL_MISMATCH);
      } else {
        setErrorType(UnifiedProvisionError.GENERAL_ERROR);
      }
      setShowErrorModal(true);
      setIsSubmitButtonDisabled(false);
      return;
    }
    await navigateToGuide();
  }, [
    friendlyNameEnabled,
    isAdminAccount,
    accountRegion,
    confirmUnifiedAccountLinking,
    navigateToGuide,
    updateFriendlyNameMutation,
    friendlyName,
  ]);

  const onRetryUnifiedAccountLinkingFlow = useCallback(async () => {
    await logoutWithTwilioAuth0({
      logoutParams: {
        returnTo: `${window.location.origin}/unified_account_linking/prompt`,
      },
    });
  }, [logoutWithTwilioAuth0]);

  const onErrorModalConfirm = useCallback(async () => {
    setShowErrorModal(false);
    if (errorType === UnifiedProvisionError.AUTH0_ERROR) {
      await redirectToUnifiedAuthWithLinking("login");
    } else {
      skipUnifiedAccountLinking();
    }
  }, [errorType, redirectToUnifiedAuthWithLinking, skipUnifiedAccountLinking]);

  const copyNewUsername = useCallback(() => {
    if (unifiedUserInfo?.email) {
      navigator.clipboard?.writeText(unifiedUserInfo?.email);
    }
  }, [unifiedUserInfo?.email]);

  const SkeletonLoaderComponent = () => (
    <Box paddingTop={"space30"} data-testid={"skeleton-loader"}>
      <SkeletonLoader width={"100%"} height={"24px"} />
      <Box paddingTop={"space30"}>
        <SkeletonLoader width={"60%"} height={"24px"} />
      </Box>
    </Box>
  );

  const FriendlyNameText = ({ accountProfile }: { accountProfile?: AccountProfileResult }) => (
    <Box data-testid={"friendly-name-text"}>
      <Paragraph marginBottom="space0">
        Your account friendly name (only visible in the console) will be{" "}
        <strong>{accountProfile?.friendly_name ?? "My SendGrid account"}</strong>
      </Paragraph>
    </Box>
  );

  return (
    <>
      <NavbarContainer showProductSwitcher={false} showUserMenu={false} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        alignItems={"center"}
        paddingTop={"space200"}
        marginBottom={"space200"}
        height={"100vh"}>
        <Box paddingTop={"space200"} maxWidth={"size60"}>
          <Card padding={"space90"}>
            <Heading as={"h1"} variant={"heading30"} marginBottom={"space0"}>
              Your Twilio credentials will now be used as your SendGrid login
            </Heading>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              columnGap={"space40"}
              paddingTop={"space90"}>
              <LogoAvatar logo="sendgrid" />
              <LinkIcon decorative color={"colorTextWeak"} />
              <LogoAvatar logo={"twilio"} />
            </Box>
            <Box paddingY={"space60"}>
              <Paragraph marginBottom="space0">
                You will now sign in to SendGrid with your Twilio login credentials, where your user
                profile and 2FA settings will be managed through Twilio. You will still be able to
                login to SendGrid using https://login.sendgrid.com/.
              </Paragraph>
            </Box>
            <Box>
              <Label htmlFor="new_sendgrid_username">New SendGrid username</Label>
              <Input
                id="new_sendgrid_username"
                name="new_sendgrid_username"
                aria-describedby="new_sendgrid_username"
                aria-readonly="true"
                type="text"
                readOnly
                value={unifiedUserInfo?.email}
                insertAfter={
                  <Text
                    as={"span"}
                    color={"colorTextLink"}
                    onClick={copyNewUsername}
                    cursor="pointer"
                    data-testid={"clipboard-icon"}>
                    <CopyIcon decorative />
                  </Text>
                }
              />
              <HelpText id="new_sendgrid_username">
                Your SendGrid username {oldUsername} has now been updated to this Twilio email
              </HelpText>
            </Box>

            {friendlyNameEnabled && (
              <Box marginTop={"space60"}>
                {accountDetailsLoading ? (
                  <SkeletonLoaderComponent />
                ) : (
                  !isSubuser &&
                  (!isAdminAccount ? (
                    <FriendlyNameText accountProfile={accountProfile} />
                  ) : (
                    <Box>
                      <Label htmlFor="account_friendlyname">Account friendly name</Label>
                      <Input
                        id="account_friendlyname"
                        name="account_friendlyname"
                        aria-describedby="account_friendlyname"
                        type="text"
                        value={friendlyName}
                        onChange={(e) => setFriendlyName(e.target.value)}
                        placeholder={"Insert account friendly name"}
                        data-testid={"friendly-name-input"}
                      />
                      {friendlyNameError ? (
                        <HelpText
                          id="new_sendgrid_username"
                          variant={"error"}
                          data-testid={"friendly-name-error-help-text"}>
                          {friendlyNameError}
                        </HelpText>
                      ) : (
                        <HelpText
                          id="new_sendgrid_username"
                          data-testid={"friendly-name-help-text"}>
                          Friendly name is only visible in the console
                        </HelpText>
                      )}
                    </Box>
                  ))
                )}
              </Box>
            )}

            <Flex hAlignContent={"right"} paddingTop={"space90"}>
              <Button
                variant="primary"
                aria-disabled={false}
                loading={isAuthInfoLoading || accountDetailsLoading}
                disabled={isSubmitButtonDisabled || Boolean(friendlyNameError)}
                onClick={onConfirmUnifiedAccountLinking}
                data-testid={"confirm-link-button"}>
                I understand
              </Button>
            </Flex>
          </Card>
        </Box>
        <AccountLinkErrorModal
          showModal={showErrorModal}
          type={errorType}
          onConfirm={onErrorModalConfirm}
          onRetry={onRetryUnifiedAccountLinkingFlow}
        />
      </Box>
      <Box height={"174px"} />
    </>
  );
};
