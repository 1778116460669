import { authorized } from "../authorized";
import { fetchWithTimeout } from "../fetch";
import { AccountRegion } from "../types/regionalApi";
import { UnifiedProvisionError, UnifiedProvisionPlanError } from "./constants";
import {
  bannerStatus,
  checkStatusCodeForError,
  is2faForbiddenError,
  parseErrorMessageFromResponse,
} from "./error";

export interface APIError {
  field?: string;
  message?: string;
  error_id?: string;
  help?: string;
}

export interface APIResponse {
  errors?: APIError[];
  status: number;
}

export interface FetchUnifiedSearchResponse extends APIResponse {
  result: { id: number; type: "mp" | "unified" }[];
}

export type FetchIdentifierResult =
  | {
      success: true;
      login_type: string;
    }
  | {
      success: false;
      reason: string;
    };

interface FetchIdentifierResponse extends APIResponse {
  login_type: string;
}

export const fetchIdentifier = async (
  username: string,
  bot_detection_token?: string
): Promise<FetchIdentifierResult> => {
  const baseURL = getBaseURL();
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    ...(bot_detection_token && { "Bot-Detection-Token": bot_detection_token }),
  };
  // Routing for SSO, Auth0, and Basic Auth
  // Build the request for the gandalf identifier endpoint
  // Call the gandalf identifier endpoint
  let response: Response | undefined;
  try {
    response = await fetchWithTimeout(`${baseURL}/v3/public/identifier`, {
      timeout: 5000,
      method: "POST",
      headers,
      body: JSON.stringify({ username }),
    });
  } catch (error: any) {
    if (error.name !== "AbortError") {
      // If it's not an AbortError throw if it is an abort error it just means we timed out.
      throw error;
    }
  }
  if (!response) {
    return {
      success: false,
      reason: bannerStatus.GeneralIdentifierError.banner,
    };
  }
  let body: FetchIdentifierResponse;
  try {
    body = await response.json();
    if (response.status === 400) {
      if (body?.errors?.[0]?.message) {
        return {
          success: false,
          reason: body?.errors?.[0]?.message,
        };
      }
      return {
        success: false,
        reason: bannerStatus.GeneralIdentifierError.banner,
      };
    }
    if (response.status === 404) {
      return {
        success: false,
        reason: bannerStatus.SSONotSetUpError.banner,
      };
    }
    if (!response.ok) {
      return {
        success: false,
        reason: bannerStatus.GeneralIdentifierError.banner,
      };
    }
    return {
      success: true,
      login_type: body?.login_type,
    };
  } catch (error) {
    return {
      success: false,
      reason: bannerStatus.GeneralIdentifierError.banner,
    };
  }
};

export interface FetchSSOResponse extends APIResponse {
  idp_url: string;
  saml_request: string;
  relay_state: string;
}

export const fetchSSO = async (username: string): Promise<Response> => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/public/sso/saml/request/${encodeURIComponent(username)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  });
};

export type FetchAuthenticateTokenResult =
  | {
      success: true;
      token: string;
      password_reset_required: boolean;
      setup_2fa_required: boolean;
      region: AccountRegion;
    }
  | {
      success: false;
      reason?: JSX.Element;
    };

interface FetchAuthenticateTokenResponse extends APIResponse {
  password_reset_required?: boolean;
  setup_2fa_required?: boolean;
  token?: string;
  region: AccountRegion;
}

export const fetchAuthenticateToken = async (
  username: string,
  password: string,
  bot_detection_token?: string
): Promise<FetchAuthenticateTokenResult> => {
  const baseURL = getBaseURL();
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    ...(bot_detection_token && { "Bot-Detection-Token": bot_detection_token }),
  };
  const response = await fetch(`${baseURL}/v3/public/tokens`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify({ username, password }),
  });
  const body: FetchAuthenticateTokenResponse = await response.json();
  if (checkStatusCodeForError(response.status)) {
    return {
      success: false,
      reason: parseErrorMessageFromResponse({
        status: response.status,
        errors: body?.errors,
      }),
    };
  }
  const { token = "", password_reset_required = false, setup_2fa_required = false, region } = body;
  return {
    success: true,
    token,
    password_reset_required,
    setup_2fa_required,
    region,
  };
};

export const deleteAuthenticateToken = async (
  token: string,
  region?: AccountRegion
): Promise<Response> => {
  const baseURL = getBaseURL();

  const fetch = authorized({ token, region: region ?? AccountRegion.Global });
  return fetch(`${baseURL}/v3/tokens`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: token }),
  });
};

export const fetchTeammate = async (
  token: string,
  username: string,
  region: AccountRegion
): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized({ token, region });
  return fetch(`${baseURL}/v3/teammates/${username}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const fetchAccountProfile = async (
  token: string,
  region: AccountRegion
): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized({ token, region });
  return fetch(`${baseURL}/v3/account/profile_v2`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const fetchFriendlyName = async (
  token: string,
  friendlyName: string,
  region: AccountRegion
): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized({ token, region });
  const requestBody = {
    friendly_name: friendlyName,
  };

  return fetch(`${baseURL}/v3/account/profile_v2`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });
};

export const fetchUserType = async (token: string, region: AccountRegion): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized({ token, region: region });
  return fetch(`${baseURL}/v3/user/type`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface FetchTwoFAResponse extends APIResponse {
  is_verified?: boolean;
}

export type FetchTwoFAResult =
  | {
      success: true;
      is_verified: boolean;
    }
  | {
      success: false;
      reason?: JSX.Element;
    };

export const fetchTwoFASetting = async (
  token: string,
  region: AccountRegion
): Promise<FetchTwoFAResult> => {
  const baseURL = getBaseURL();
  const fetch = authorized({ token, region });
  const response = await fetch(`${baseURL}/v3/public/access_settings/multifactor`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response) {
    return { success: false, reason: <>bannerStatus.TwoFAError.banner</> };
  }
  if (response.status === 404) {
    return {
      success: true,
      is_verified: false,
    };
  }
  let body: FetchTwoFAResponse | undefined;
  try {
    body = (await response.json()) as FetchTwoFAResponse;
  } catch (e) {}
  if (response.status !== 200 && response.status !== 404) {
    return {
      success: false,
      reason: parseErrorMessageFromResponse({
        status: response.status,
        errors: body?.errors,
      }),
    };
  }
  return {
    success: true,
    is_verified: body?.is_verified ?? false,
  };
};

/**
 * Session Token Results and Authenticate Token Results are identical types they duplicate
 * the fields since they are pulling different information.
 */
export type FetchSessionTokenResult =
  | {
      success: true;
      token: string;
      password_reset_required?: boolean;
      setup_2fa_required?: boolean;
      region: AccountRegion;
    }
  | {
      success: false;
      reason?: JSX.Element | string;
    };

interface FetchSessionTokenResponse extends APIResponse {
  password_reset_required?: boolean;
  setup_2fa_required?: boolean;
  token?: string;
  region: AccountRegion;
}

// Fetch the session token and navigate to session token landing point.
export const fetchSessionToken = async (access_token: string): Promise<FetchSessionTokenResult> => {
  let response: Response | undefined;
  try {
    const baseURL = getBaseURL();
    response = await fetchWithTimeout(`${baseURL}/v3/sessions`, {
      timeout: 5000,
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  } catch (error: any) {
    if (error.name !== "AbortError") {
      // If it's not an AbortError throw if it is an abort error it just means we timed out.
      throw error;
    }
  }
  if (!response) {
    return { success: false, reason: bannerStatus.GeneralSessionTokenError.banner };
  }
  if (!response.ok && response.status === 401) {
    // Just means the user doesn't exist
    return { success: false };
  }
  let body: FetchSessionTokenResponse;
  try {
    body = await response.json();
  } catch {
    return {
      success: false,
      reason: bannerStatus.GeneralSessionTokenError.banner,
    };
  }
  const { token = "", password_reset_required = false, setup_2fa_required = false, region } = body;
  if (response.status >= 200 && response.status < 300 && body) {
    // Means the user exists and is ready
    return {
      success: true,
      token,
      password_reset_required,
      setup_2fa_required,
      region,
    };
  }
  // If anything else shows up throw a fetchError based on the return status.
  if (checkStatusCodeForError(response.status)) {
    // Check if it's an IPAM error
    const errors = body?.errors ?? [];
    const ipamError = errors.some(
      (err) => err.message === "The requestor's IP Address is not whitelisted"
    );
    if (ipamError) {
      return {
        success: false,
        reason: "The requestor's IP Address is not whitelisted",
      };
    }
    // If it's not the IPAM error return the error message.
    return {
      success: false,
      reason: parseErrorMessageFromResponse({
        status: response.status,
        errors,
      }),
    };
  }
  return {
    success: false,
    reason: bannerStatus.GeneralSessionTokenError.banner,
  };
};

export interface FetchAcceptInviteResponse extends APIResponse {
  token: string;
  setup_2fa_required: boolean;
}

export interface FetchAcceptInviteBody {
  first_name: string;
  last_name: string;
  email: string;
  username: string;
}

export const fetchAcceptInvite = async (
  token: string,
  body: FetchAcceptInviteBody
): Promise<Response> => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/public/teammates/${token}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
};

export const fetchUnifiedSearch = async (access_token: string, id_token: string) => {
  const baseURL = getBaseURL();
  // unauthenticated endpoint in sg-gateway, using global region.
  const fetch = authorized({ bearer: access_token, region: AccountRegion.Global });
  return fetch(`${baseURL}/v3/unified_search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id_token,
    }),
  });
};

export const fetchUnifiedSignup = async (
  access_token: string,
  id_token: string
): Promise<Response> => {
  const baseURL = getBaseURL();
  // unauthenticated endpoint in sg-gateway, using global region.
  const fetch = authorized({ bearer: access_token, region: AccountRegion.Global });
  return fetch(`${baseURL}/v3/unified_signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id_token,
    }),
  });
};
export const fetchUnifiedLink = async (
  access_token: string,
  accept_token: string,
  id_token: string
): Promise<APIResponse | null> => {
  const baseURL = getBaseURL();
  // unauthenticated endpoint in sg-gateway, using global region.
  const fetch = authorized({ bearer: access_token, region: AccountRegion.Global });
  const response = await fetch(`${baseURL}/v3/unified_link_v2/${accept_token}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ id_token }),
  });

  if (response.ok) return null;

  try {
    const body: APIResponse = await response.json();
    return {
      status: response.status,
      errors: body.errors ?? [
        {
          error_id: "ERR_TEAMMATE_INVITE_INTERNAL",
          message: "A problem occurred while accepting the invite.",
        },
      ],
    };
  } catch {
    return {
      status: response.status,
      errors: [
        {
          error_id: "ERR_TEAMMATE_INVITE_INTERNAL",
          message: "Received an unexpected response while accepting invite.",
        },
      ],
    };
  }
};

export type UnifiedProvisionPlanResponse =
  | {
      eligible: true;
      email: string;
      auth0_login_exists: boolean;
      rollout_phase: number;
      base: number;
    }
  | {
      eligible: false;
      reason: string;
      errors?: APIError[];
    };

/**
 * Call unified provision plan to check account linking eligibility.
 * @param token
 */
export const fetchUnifiedProvisionPlan = async (
  token: string,
  region: AccountRegion
): Promise<UnifiedProvisionPlanResponse> => {
  const baseURL = getBaseURL();
  const fetch = authorized({ token, region });
  const response = await fetch(`${baseURL}/v3/unified_provision/plan`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    const responseJson = await response.json();
    if (response.status === 403 && is2faForbiddenError(responseJson)) {
      return {
        eligible: false,
        reason: UnifiedProvisionPlanError.VALIDATE_2FA_REQUIRED,
      };
    }
    return {
      eligible: false,
      reason: UnifiedProvisionPlanError.GENERAL_ERROR,
    };
  }
  return await response.json();
};

export type UnifiedProvisionResponse =
  | {
      success: true;
    }
  | {
      success: false;
      reason: UnifiedProvisionError;
      errors?: APIError[];
    };

/**
 * Call unified provision to link sendgrid account to twilio account.
 * @param token
 * @param id_token
 */
export const fetchUnifiedProvision = async (
  token: string,
  id_token: string,
  region: AccountRegion
): Promise<UnifiedProvisionResponse> => {
  const baseURL = getBaseURL();
  const fetch = authorized({ token, region });
  const response = await fetch(`${baseURL}/v3/unified_provision`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id_token,
    }),
  });
  if (!response.ok) {
    const responseBody = await response.json();
    let error = UnifiedProvisionError.GENERAL_ERROR;
    if (
      response.status === 400 &&
      responseBody.errors[0].message === UnifiedProvisionError.USERNAME_IN_USE
    ) {
      error = UnifiedProvisionError.USERNAME_IN_USE;
    }
    if (
      response.status === 400 &&
      responseBody.errors[0].message === UnifiedProvisionError.AUTH0_EMAIL_MISMATCH
    ) {
      error = UnifiedProvisionError.AUTH0_EMAIL_MISMATCH;
    }
    return { success: false, reason: error };
  }
  return { success: true };
};

const getBaseURL = (): string => {
  return process.env.REACT_APP_API_BASE_URL || "https://api.sendgrid.com";
};
