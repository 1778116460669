import { getRegionalApiUrl, missingAccountRegionErrorMessage } from "./regionalApi";
import { AccountRegion } from "./types/regionalApi";

export interface AuthorizedFunction {
  (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
}

export interface AuthorizedOptions {
  token?: string;
  bearer?: string;
  region: AccountRegion;
}

export function authorized(opts: AuthorizedOptions): AuthorizedFunction {
  const { token, bearer, region } = opts;

  if (region === undefined) {
    throw new Error(missingAccountRegionErrorMessage);
  }

  const authorization = bearer ? `bearer ${bearer}` : `token ${token}`;

  return (input: RequestInfo | URL, opts?: RequestInit) => {
    const init = {
      ...opts,
      headers: {
        ...opts?.headers,
        Authorization: authorization,
      },
    };

    const url = getUrlFromRequestInfo(input);
    const regionalApiUrl = getRegionalApiUrl({
      url,
      region,
    });

    return fetch(regionalApiUrl.toString(), init);
  };
}

export const getUrlFromRequestInfo = (input: RequestInfo | URL): URL => {
  if (input instanceof URL) {
    return input;
  }

  if (input instanceof Request) {
    return new URL(input.url);
  }

  return new URL(input);
};
