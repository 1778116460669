import { RumInitConfiguration } from "@datadog/browser-rum";

const shouldRunDataDog = process.env.REACT_APP_ANALYTICS_ENABLED === "true";

export const getDatadogRumOptions = (): RumInitConfiguration | undefined => {
  if (!shouldRunDataDog) {
    return undefined;
  }
  return {
    applicationId: "7f622dbe-dbb4-4a8b-a84f-b04a06dbe020",
    clientToken: "pub8bc8a0e03e4d24e31f47b86a78e8d94b",
    site: "datadoghq.com",
    service: "email-login",
    env: process.env.REACT_APP_ANALYTICS_ENV,
    version: process.env.REACT_APP_ANALYTICS_VERSION || "dev",
    traceSampleRate: 100,
    trackUserInteractions: true,
  };
};
