import { analytics } from "@sendgrid/bloodhound";
import { AnalyticsOptions } from "@sendgrid/bloodhound/dist/src/config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Theme } from "@twilio-paste/core/theme";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./App";
import { signupContext, websiteContext } from "./context";
import { cookieSetOptions } from "./cookieSetOptions";
import { getDatadogRumOptions } from "./datadogRumOptions";
import { ExternalNavigationProvider } from "./external-navigate";
import reportWebVitals from "./reportWebVitals";
import "./styles/login.scss";
import { UnifiedAuthProvider } from "./unified-auth";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient();

const websiteConfig = {
  basename: process.env.REACT_APP_WEBSITE_URL || "https://sendgrid.com",
  context: websiteContext,
};

const signupConfig = {
  basename: process.env.REACT_APP_SIGNUP_HOST || "https://signup.sendgrid.com",
  context: signupContext,
};

const unifiedAuthProvider = {
  proxyDomainForAuthorize: process.env.REACT_APP_PROXY_DOMAIN_FOR_AUTHORIZE,
  domain: process.env.REACT_APP_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  audience: process.env.REACT_APP_AUDIENCE,
  enableUnifiedAccountLinking:
    process.env.REACT_APP_ENABLE_UNIFIED_ACCOUNT_LINKING === "true" || false,
  // @ts-ignore
  analytics: analytics,
};

root.render(
  <React.StrictMode>
    <Theme.Provider theme="twilio">
      <ExternalNavigationProvider {...signupConfig}>
        <ExternalNavigationProvider {...websiteConfig}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <CookiesProvider defaultSetOptions={cookieSetOptions()}>
                <UnifiedAuthProvider {...unifiedAuthProvider}>
                  <AppRoutes />
                </UnifiedAuthProvider>
              </CookiesProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </ExternalNavigationProvider>
      </ExternalNavigationProvider>
    </Theme.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

// We want to make analytics work with bloodhound.
const options = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "https://api.sendgrid.com",
  segmentApiKey: process.env.REACT_APP_SEGMENT_API_KEY || "",
  datadogRum: getDatadogRumOptions(),
} as AnalyticsOptions;

analytics.init(options);
