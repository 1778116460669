import { AppState, AuthorizationParams, User } from "@auth0/auth0-react";
import { createContext } from "react";
import { AccountRegion } from "../types/regionalApi";
import { UnifiedProvisionError, UnifiedProvisionPlanError } from "./constants";

export interface SSOInfo {
  idp_url: string;
  saml_request: string;
  relay_state: string;
}

interface Error {
  name: string;
  message: string;
  stack?: string;
}

/**
 * The unified auth state which, when combined with the auth methods, make up the return object of the `useUnifiedAuth` hook.
 */
export interface UnifiedAuthState {
  error?: Error | JSX.Element;
  /**
   * isAuthenticatedWithSendGrid returns true if the user is authenticated with SendGrid and ready to navigate to the dashboard.
   */
  isAuthenticatedWithSendGrid: boolean;
  /**
   * isLoadingWithSendGrid returns true if the user is attempting to retrieve data from SendGrid.
   */
  isLoadingWithSendGrid: boolean;
  ssoLoginInfo?: SSOInfo;
  isSSOLogin: boolean;
}

export const initialUnifiedAuthState: UnifiedAuthState = {
  isSSOLogin: false,
  isAuthenticatedWithSendGrid: false,
  isLoadingWithSendGrid: false,
};

export interface NavigateOptions {
  /**
   * The identifier needing to be looked up.
   */
  identifier: string;
  /**
   * An optional bot detection token.
   */
  bot_detection_token?: string;
}

export interface BasicAuthLoginOptions {
  /**
   * The username to log into.
   */
  username: string;

  /**
   * The password to use to log into it with.
   */
  password: string;

  /**
   * An optional bot detection token.
   */
  bot_detection_token?: string;
}

export interface Auth0RedirectLoginOptions {
  /**
   * The potential email
   */
  email?: string;
  /**
   * screen hint suggests to use a signup or a login.
   */
  screen_hint?: "signup" | "login" | string;
  /**
   * If this is used by with an invitation flow store the token with the appState
   */
  token?: string;

  /**
   *
   */
  fromUnifiedAccountLink?: boolean;

  /**
   * These are additional authorization parameters that can be passed to the Auth0
   * loginWithRedirect method they will be directly copied the /authorize call.
   */
  additionalAuthorizationParams?: AuthorizationParams;
}

export interface LogoutOptions {
  /**
   * Used to control the redirect.
   * Set to `false` to disable the redirect, or provide a function to handle the actual redirect yourself.
   *
   * @example
   * await logout({
   *   openUrl(url) {
   *     window.location.replace(url);
   *   }
   * });
   *
   * @example
   * import {useNavigate} from "react-router-dom";
   *
   * await logout({
   *   async openUrl(url) {
   *     navigate('/logged-out');
   *   }
   * });
   */
  openUrl?: false | ((url: string) => Promise<void> | void);
  /**
   * Where to returnTo after you are finished with your logout.
   */
  returnTo?: string;
  /**
   * The region of the account, used to route the user to the correct regional api host.
   */
  region?: AccountRegion;
}

export type UnifiedAccountLinkType = "login" | "signup";

export type ConfirmAccountLinkingResult =
  | {
      success: true;
    }
  | {
      success: false;
      error: UnifiedProvisionError;
    };

export type UnifiedProvisionEligibilityResult =
  | {
      eligible: true;
      email: string;
    }
  | {
      eligible: false;
      error?: UnifiedProvisionPlanError;
    };

export type AccountProfileResult = {
  friendly_name: string;
};

export type UserTypeResult = {
  type: string;
  region: AccountRegion;
};

/**
 * Contains the authenticated state and authenticated methods provided by the useUnifiedAuth hook.
 */
export interface UnifiedAuthContextInterface extends UnifiedAuthState {
  /**
   * After the browser redirects back to the callback page, handles the Auth0 responses.
   */
  handleRedirectCallback: (appState?: AppState, user?: User) => Promise<void>;

  /**
   * ```js
   * isLoading;
   * ```
   * Returns true if either we are trying to pull data from SendGrid *OR* we are retrieving data from Auth0.
   */
  isLoading: boolean;

  /**
   * ```js
   * await loginWithAuth0(options);
   * ```
   * Performs a loginWithRedirect using auth0.
   */
  loginWithAuth0: (options?: Auth0RedirectLoginOptions) => Promise<void>;

  /**
   * ```js
   * await loginWithBasicAuth(options);
   * ```
   * loginWithBasicAuth takes an identifier and a password and attempts to log in using basic auth.
   */
  loginWithBasicAuth: (options?: BasicAuthLoginOptions) => Promise<void>;

  /**
   * ```js
   * await logout(options);
   * ```
   * clears the application session and redirect after all the sessions have been cleared.
   */
  logout: (options: LogoutOptions) => Promise<void>;

  /**
   * ```js
   * await navigate('');
   * ```
   * navigate takes an option and attempts to redirect to the correct system to authenticate.
   */
  navigate: (opts: NavigateOptions) => Promise<void>;

  /**
   * ```js
   * navigateToGuide();
   * ```
   * If a user is already logged in, this will navigate to the guide page.
   */
  navigateToGuide: () => Promise<void>;

  /**
   * ```js
   * redirectToUnifiedAuthWithLinking();
   * ```
   * Redirects to the unified auth page for account linking.
   */
  redirectToUnifiedAuthWithLinking: (
    linkType: UnifiedAccountLinkType,
    prefillEmail?: string
  ) => Promise<void>;

  /**
   * ```js
   * skipUnifiedAccountLinking();
   * ```
   * Skips the unified account linking process and redirects to mako.
   */
  skipUnifiedAccountLinking: () => void;

  /**
   * ```js
   * goToLoginIfNotAuthenticatedWithSendgrid();
   * ```
   * Protects the unified account linking routes from unauthorized access.
   */
  goToLoginIfNotAuthenticatedWithSendgrid: () => void;

  /**
   * ```js
   * confirmUnifiedAccountLinking();
   * ```
   * Confirms the unified account linking process.
   */
  confirmUnifiedAccountLinking: (
    accountRegion: AccountRegion
  ) => Promise<ConfirmAccountLinkingResult>;

  fetchUnifiedAccountLinkingEligibility: (
    accountRegion: AccountRegion
  ) => Promise<UnifiedProvisionEligibilityResult>;

  getAccountProfile: (accountRegion: AccountRegion) => Promise<AccountProfileResult>;
  updateFriendlyName: (friendlyName: string, accountRegion: AccountRegion) => Promise<void>;
  checkIfTeammateIsAdmin: (accountRegion: AccountRegion) => Promise<boolean>;
  getUserType: (accountRegion: AccountRegion) => Promise<UserTypeResult>;
}

/**
 * @ignore
 */
const stub = (): never => {
  throw new Error("You forgot to wrap your component in <UnifiedAuthProvider>.");
};

/**
 * @ignore
 */
export const initialContext = {
  ...initialUnifiedAuthState,
  isLoading: false,
  navigate: stub,
  loginWithBasicAuth: stub,
  loginWithAuth0: stub,
  handleRedirectCallback: stub,
  navigateToGuide: stub,
  logout: stub,
  redirectToUnifiedAuthWithLinking: stub,
  skipUnifiedAccountLinking: stub,
  goToLoginIfNotAuthenticatedWithSendgrid: stub,
  confirmUnifiedAccountLinking: stub,
  fetchUnifiedAccountLinkingEligibility: stub,
  checkIfTeammateIsAdmin: stub,
  getAccountProfile: stub,
  updateFriendlyName: stub,
  getUserType: stub,
};

export const UnifiedAuthContext = createContext<UnifiedAuthContextInterface>(initialContext);
