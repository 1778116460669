import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { Loading } from "../../Loading";
import { useSendGridSession } from "../../sendgrid-auth";
import { useAccountRegionCookie } from "../../sendgrid-auth/useAccountRegionCookie";
import { AccountRegion } from "../../types/regionalApi";
import { useUnifiedAuth } from "../../unified-auth";
import { UserTypeResult } from "../../unified-auth/context";
import { fetchCAMAccount, fetchLogoutConfig } from "./fetch";

export const logoutPathQueryFunction = async (
  makoToken: string | undefined,
  accountRegion: AccountRegion | undefined
) => {
  try {
    // If a mako token or accountRegion is not defined then always just return '/';
    if (typeof makoToken === "undefined" || !accountRegion) {
      return "/";
    }
    const logoutConfig = await fetchLogoutConfig();
    const camAccount = await fetchCAMAccount(makoToken, accountRegion);
    const catalogOrg = camAccount?.catalog_org;
    return (logoutConfig && logoutConfig[catalogOrg])?.sso_logout || `/`;
  } catch (error) {
    // If something goes wrong just return '/'
    return "/";
  }
};

export const Logout = () => {
  const { logout, getUserType } = useUnifiedAuth();
  const { makoToken, isAuthenticated } = useSendGridSession();
  const didLogout = useRef(false);
  const { accountRegion, setAccountRegionCookie } = useAccountRegionCookie();

  const { data: userType, isError: isGetUserTypeError } = useQuery<UserTypeResult>({
    enabled: isAuthenticated && !Boolean(accountRegion),
    queryKey: ["getUserType"],
    queryFn: async () => {
      const result = await getUserType(AccountRegion.Global);

      setAccountRegionCookie(result.region);
      return result;
    },
    staleTime: 0,
  });

  const { data: path } = useQuery({
    queryKey: ["logoutPath", makoToken],
    enabled: isAuthenticated && Boolean(accountRegion),
    queryFn: () => logoutPathQueryFunction(makoToken, accountRegion),
    staleTime: 0,
  });

  const checkIfLoggedOut = () => {
    if (!didLogout.current) {
      didLogout.current = true;
      return false;
    }

    return didLogout.current;
  };

  useEffect(() => {
    (async () => {
      if (path) {
        if (checkIfLoggedOut()) {
          return;
        }
        await logout({ returnTo: path, region: accountRegion ?? userType?.region });
        return;
      }

      if (isGetUserTypeError) {
        if (checkIfLoggedOut()) {
          return;
        }
        await logout({ returnTo: "/", region: accountRegion ?? userType?.region });
      }
    })();
  }, [logout, path, didLogout, accountRegion, isGetUserTypeError, userType]);

  return <Loading />;
};
