import { useCallback, useMemo } from "react";
import { useCookies } from "react-cookie";
import { cookieSetOptions } from "../cookieSetOptions";
import { AccountRegion } from "../types/regionalApi";
import { ACCOUNT_REGION } from "./constants";

export const useAccountRegionCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies([ACCOUNT_REGION]);
  const cookieOptions = cookieSetOptions();

  const accountRegion = useMemo(() => {
    const region = cookies.account_region as AccountRegion | undefined;
    if (!region) {
      return undefined;
    }
    return region;
  }, [cookies]);

  const setAccountRegionCookie = useCallback(
    (value: AccountRegion) => {
      setCookie(ACCOUNT_REGION, value, cookieOptions);
    },
    [cookieOptions, setCookie]
  );

  const removeAccountRegionCookie = useCallback(() => {
    removeCookie(ACCOUNT_REGION, cookieOptions);
  }, [cookieOptions, removeCookie]);

  return { accountRegion, setAccountRegionCookie, removeAccountRegionCookie };
};
