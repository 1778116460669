import { authorized } from "../../authorized";
import { AccountRegion } from "../../types/regionalApi";

export const logoutConfigURL =
  process.env.REACT_APP_LINKS_URL || "https://links.integrations.sendgrid.net/links.json";

export const accountURL = process.env.REACT_APP_API_BASE_URL
  ? `${process.env.REACT_APP_API_BASE_URL}/v3/accounts`
  : "https://api.sendgrid.com/v3/accounts";

export interface LogoutConfigResponse {
  billing: string;
  sso_logout: string;
}

export interface LogoutConfigResponses {
  [index: string]: LogoutConfigResponse;
}

// A by-org mapping of logout destinations
/* Example response:
  {
    "azure": {
      "billing": "https://portal.azure.com/#blade/Microsoft_Azure_GTM/ModernBillingMenuBlade/Overview",
      "sso_logout": "https://portal.azure.com/"
    },
    "google": {
      "billing": "https://console.cloud.google.com/billing",
      "sso_logout": "https://console.cloud.google.com/"
    },
    "heroku": {
      "billing": "https://dashboard.heroku.com/account/billing",
      "sso_logout": "https://id.heroku.com/login"
    },
    "ibm": {
      "billing": "https://cloud.ibm.com/billing",
      "sso_logout": "https://cloud.ibm.com/"
    },
    "kke": {
      "billing": "https://sendgrid.kke.co.jp/app?p=mypage.billing_menu",
      "sso_logout": "https://sendgrid.kke.co.jp/app?p=login.index"
    }
  }
*/
export async function fetchLogoutConfig(): Promise<LogoutConfigResponses> {
  const response = await fetch(logoutConfigURL);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return (await response.json()) as LogoutConfigResponses;
}

export interface CAMAccountResponse {
  catalog_org: string;
}
export async function fetchCAMAccount(
  token: string,
  region: AccountRegion
): Promise<CAMAccountResponse> {
  const fetch = authorized({ token, region });
  const response = await fetch(accountURL);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return (await response.json()) as CAMAccountResponse;
}
